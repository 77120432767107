<template>
  <div class="ny_anli">
    <div class="ny_anli_bg">
      <img :src="basicData.msg_bg" alt="" style="width:100%" />
    </div>
    <div class="ny_anli_nav">
      <div class="nav_col">
        <ul class="nav_col_cp">
          <h2
            v-if="Object.keys(cpHead).length != 0"
            :style="{
              background: `url(${cpHead.cp_img}) 100% no-repeat`
            }"
          >
            {{ basicData.msg_title[0].value }}
            <b style="display:block">{{ basicData.msg_title[0].key }}</b>
          </h2>
          <li
            v-for="(item, idx) in cpList"
            :key="idx"
            :class="[currentIdx == idx ? 'xz_li' : '']"
          >
            <a
              :href="'#/ny_product?currentIdx=' + currentIdx"
              @click="switchList(item, idx)"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
        <ul
          class="nav_col_cp"
          style="margin-top:0.5rem"
          v-if="Object.keys(basicData).length != 0"
        >
          <h2
            :style="{
              background: `url(${cpHead.cp_img}) 100% no-repeat`
            }"
          >
            {{ basicData.msg_title[1].value }}
            <b style="display:block">{{ basicData.msg_title[1].key }}</b>
          </h2>
          <div class="cp_tit">
            <img :src="basicData.lx_icon" alt="" />
            <h3>{{ basicData.msg_title[2].key }}</h3>
            <h4>{{ basicData.msg_title[2].value }}</h4>
          </div>
          <li v-for="(item, idx) in basicData.lx_list" :key="idx">
            <p>{{ item.key }}{{ item.value }}</p>
          </li>
        </ul>
      </div>
      <div class="nav_list">
        <div class="nav_list_head">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span class="el-breadcrumb__item" style="margin-right:8px"
              ><i class="el-icon-s-home"></i> 当前位置：</span
            >
            <el-breadcrumb-item
              v-for="(item, idx) in cpHead.cp_list"
              :key="idx"
              :to="{ path: item.value }"
              @click="allProduct(form)"
            >
              {{ item.key }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="nav_list_col" v-if="!currentId">
          <ul class="item" v-if="product.length != 0">
            <li
              v-for="(item, idx) in product"
              :key="idx"
              @click="goDetails(item)"
            >
              <a
                :href="
                  '#/ny_product?currentIdx=' +
                    currentIdx +
                    '&currentId=' +
                    item.id
                "
              >
                <div class="list_image">
                  <img
                    :src="item.site_images"
                    alt=""
                    style="width:100%;height: 100%;"
                  />
                  <div class="all_mark">
                    <span>——</span>
                    <div class="mark_div two_ellipsis">{{ item.title }}</div>
                    <span>——</span>
                  </div>
                </div>
                <div class="list_title ellipsis">{{ item.title }}</div>
              </a>
            </li>
            <onLoading :show="loading"></onLoading>
          </ul>
          <div class="details_empty" v-else>
            <img
              src="@/assets/image/xwdt/xwdt-data.webp"
              alt=""
              style="margin:0 auto"
            />
          </div>
          <div class="all-pagination">
            <el-pagination
              prev-text="上一页"
              next-text="下一页"
              @current-change="handleCurrentChange"
              :current-page.sync="form.page"
              background
              :page-size="8"
              layout="prev, pager, next,total"
              :total="total"
              v-if="total > 8"
            >
            </el-pagination>
            <span class="no_meet_conditions" v-else>暂无更多数据</span>
          </div>
        </div>
        <div class="nav_list_html" v-else v-html="sjData.maincontent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductTypes, getAllList, getAlDetail, getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      basicData: {},
      cpList: [],
      currentId: 0,
      currentIdx: 0,
      cpHead: {},
      product: [],
      loading: false,
      form: {
        page: 1,
        pagesize: 8,
        type_id: '',
        project: ''
      },
      total: 0,
      sjData: {}
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (!this.$route.query.currentIdx) {
          this.currentIdx = 0
          this.form.type_id = ''
          this.allProduct(this.form)
        }else{
          this.currentIdx = this.$route.query.currentIdx      
          this.getType()  
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      if (that.$route.query.currentId) {
        that.currentId = that.$route.query.currentId
        that.goDetails({ id: that.currentId })
      } else {
        that.currentId = 0
      }
      let id = sessionStorage.getItem(that.changeData() + 'id')
      let project = sessionStorage.getItem(this.changeData() + 'project')
      that.form.project = project
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          console.log(res.data.data)
          that.basicData = res.data.data.基本信息
          that.cpHead = res.data.data.basic
          that.getType()
        }
      })
    },
    getType () {
      let that = this
      let project = sessionStorage.getItem(this.changeData() + 'project')
      getProductTypes(project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部产品',
            id: ''
          })
          that.cpList = res.data.data
          if (that.$route.query.currentIdx) {
            that.currentIdx = that.$route.query.currentIdx
            this.form.type_id = that.cpList[that.currentIdx].id
            that.allProduct(that.form)
          } else {
            that.currentIdx = 0
            that.allProduct(that.form)
          }
          if (that.cpHead.cp_list != 0) {
            that.cpHead.cp_list[2].key = that.cpList[that.currentIdx].name
          }
        }
      })
    },
    switchList (e, i) {
      this.currentIdx = i
      this.currentId = 0
      this.form.type_id = e.id
      this.allProduct(this.form)
      if (this.cpHead.cp_list != 0) {
        this.cpHead.cp_list[2].key = e.name
      }
    },
    allProduct (e) {
      this.loading = true
      getAllList(e).then(res => {
        if (res.data.code == 1) {
          this.product = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    goDetails (e) {
      this.currentId = e.id
      this.sjData = {}
      getAlDetail(e.id, this.form.project).then(res => {
        if (res.data.code == 1) {
          this.sjData = res.data.data
        }
      })
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.allProduct(this.form)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.ny_anli {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .ny_anli_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .ny_anli_nav {
    margin: 1rem auto;
    width: 1400px;
    display: flex;
    flex-wrap: wrap;
    .nav_col {
      width: 226px;
      h2 {
        color: rgb(255, 255, 255);
        text-align: center;
        font-size: 20px;
        padding: 12px 0 25px;
        font-weight: normal;
        line-height: 1.4;
        margin-bottom: 0.1rem;
        width: 100%;
      }
      .nav_col_cp {
        padding-bottom: 0.1rem;
        border-width: 3px 1px 1px;
        border-style: solid;
        border-color: #3da136;
        box-sizing: border-box;
        font-size: 14px;
        background-color: #fff;
        .cp_tit {
          text-align: center;
          padding-bottom: 0.2rem;
          margin-bottom: 0.1rem;
          border-bottom: 1px solid #ddd;
          box-sizing: border-box;
          img {
            margin-top: 0.1rem;
          }
          h3 {
            margin: 0.15rem 0;
          }
          h4 {
            color: rgb(61, 160, 54);
            font-size: 0.25rem;
            font-weight: 600;
          }
        }
        li {
          text-align: center;
          line-height: 2.5;
          padding: 0 0.2rem;
          p {
            text-align: left;
          }
        }
        .xz_li {
          color: #3da136;
          font-weight: 600;
        }
      }
    }
    .nav_list {
      width: calc(100% - 250px);
      margin-left: 24px;
      .nav_list_head {
        padding: 0.15rem 0;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
      }
      .nav_list_col {
        margin-top: 0.3rem;
        .item,
        .details_empty {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
          position: relative;
          li {
            padding: 0.1rem;
            display: inline-block;
            font-size: 0.18rem;
            vertical-align: middle;
            width: calc(25% - 0.2rem);
            cursor: pointer;
            .list_image {
              width: 100%;
              position: relative;
            }
            .list_title {
              padding: 0.2rem;
              background-color: #fff;
              text-align: center;
            }
          }
          li:hover {
            color: #0a8ce2;
            .all_mark {
              height: 100%;
            }
          }
        }
        .details_empty {
          background-color: #fff;
        }
      }
      .nav_list_html {
        margin-top: 0.3rem;
        line-height: 1.5;
      }
    }
  }
}
</style>
